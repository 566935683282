import { AddIcon } from 'assets/images/AddIcon/AddIcon';
import { CollapseIcon } from 'assets/images/CollapseIcon/CollapseIcon';
import { DeleteIcon } from 'assets/images/DeleteIcon/DeleteIcon';
import { EditIcon } from 'assets/images/EditIcon/EditIcon';
import { ExpandIcon } from 'assets/images/ExpandIcon/ExpandIcon';
import { PDFIcon } from 'assets/images/PDFIcon/PDFIcon';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  displayCompletionFormStatus,
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';
import { ConfirmationModal } from 'components/common/modals/ConfirmatinModal';
import { ROUTES_STRING } from 'constants/routes';
import { ModuleACL, UserType } from 'enums/entitlements.ts';
import { PurchaseOrderStatus } from 'enums/statuses.ts';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  addInvoiceSignature,
  addPOContact,
  completionFormSubmitRequest,
  deletePOContact,
  fetchPurchaseOrderDetails,
  initialReviewResponsePurchaseOrder,
  requestVendorCompletionForm,
  updatePOContact,
  setApprovedButtonStatus,
  setIsPDFLoading,
  setLoading,
} from 'store/slices/purchaseOrder/purchaseOrderDetailsSlice';
import { IsAllowedACL, filterAllowedOptionsACL } from 'utils/aclHelper';
import AffidavitForm from '../../CompletionForm/AffidavitForm/AffidavitForm';
import { AddNewContactModal } from './AddNewContactModal';
import { AddSignatureModal } from './AddSignatureModal';
import { CommentsModal } from './CommentsModal';
import { DeleteContactModal } from './DeleteContactModal';
import { EditContactModal } from './EditContactModal';
import './PurchaseOrderDetails.scss';
import { WorkAuthPDF } from './WorkAuthPDF';
import { usePromiseTracker } from 'react-promise-tracker';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { fileViewer } from 'components/Dialogs/FileViewer';

const PurchaseOrderDetails = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(state => state.auth);
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);
  const [expandedRows, setExpandedRows] = useState([]);
  const [signatureModal, setSignatureModal] = useState(false);
  const [signatureModalForWorkAuth, setSignatureModalForWorkAuth] =
    useState(false);

  const [commentsModal, setCommentsModal] = useState(false);
  const [signatureBase64, setSignatureBase64] = useState(String);
  const [canAgree, setCanAgree] = useState(true);
  const [canDisagree, setCanDisagree] = useState(true);
  const [tabs, setTabs] = useState([]);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [completionFormModal, setCompletionFormModal] = useState(false);

  const [loader, setLoader] = useState(false);

  const intialRateReviewHeaders = [
    {
      key: 'details',
      value: 'Details',
      permission: 'can_view_details_purchaseorder',
    },

    {
      key: 'address',
      value: 'Address',
      permission: 'can_view_address_purchaseorder',
    },
    {
      key: 'contactInfo',
      value: 'Contact Info',
      permission: 'can_view_contact_info_purchaseorder',
    },
    {
      key: 'poLines',
      value: 'PO Lines ',
      permission: 'can_view_polines_purchaseorder',
    },
    {
      key: 'workAuth',
      value: 'Work Auth',
      permission: 'can_view_work_auth_purchaseorder',
    },
    {
      key: 'additionalAttachments',
      value: 'Additional Attachments',
      permission: 'can_view_additional_attach_purchaseorder',
    },
  ];

  let poTabs = [
    {
      key: 'details',
      value: 'Details',
      permission: 'can_view_details_purchaseorder',
    },
    {
      key: 'contactInfo',
      value: 'Contact Info',
      permission: 'can_view_contact_info_purchaseorder',
    },
    {
      key: 'poLines',
      value: 'PO Lines',
      permission: 'can_view_polines_purchaseorder',
    },
  ];

  let poTabsWithAddress = [
    {
      key: 'details',
      value: 'Details',
      permission: 'can_view_details_purchaseorder',
    },
    {
      key: 'contactInfo',
      value: 'Contact Info',
      permission: 'can_view_contact_info_purchaseorder',
    },
    {
      key: 'poLines',
      value: 'PO Lines',
      permission: 'can_view_polines_purchaseorder',
    },
    {
      key: 'address',
      value: 'Address',
      permission: 'can_view_address_purchaseorder',
    },
  ];

  let poDetailsTabs = [
    {
      key: 'details',
      value: 'Details',
      permission: 'can_view_details_purchaseorder',
    },
    {
      key: 'contactInfo',
      value: 'Contact Info',
      permission: 'can_view_contact_info_purchaseorder',
    },
  ];

  const roles = [
    {
      label: 'Vendor PO Contact',
      value: 'vendorContact',
    },
    {
      label: 'Vendor Timesheet Contact',
      value: 'vendorTimesheetContact',
    },
    {
      label: 'Invoice Contact',
      value: 'invoiceContact',
    },
  ];

  let status =
    location.state.status === PurchaseOrderStatus.PENDING_INITIAL_REVIEW
      ? PurchaseOrderStatus.PENDING_INITIAL_REVIEW
      : '';

  let optionsTab = intialRateReviewHeaders;
  // (location.state.status === PurchaseOrderStatus.APPROVED &&
  //   location.state.status !== 'undefined') ||
  // (location.state.status ===
  //   PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW &&
  //   location.state.status !== 'undefined') ||
  // (location.state.status ===
  //   PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW_SUBMITTED &&
  //   location.state.status !== 'undefined') ||
  // ((location.state.status === PurchaseOrderStatus.PENDING_INITIAL_REVIEW ||
  //   location.state.status ===
  //     PurchaseOrderStatus.PENDING_INITIAL_REVIEW_DECLINED ||
  //   location.state.status ===
  //     PurchaseOrderStatus.PENDING_INITIAL_REVIEW_SUBMITTED) &&
  //   location.state.status !== 'undefined') ||
  // (location.state.status === PurchaseOrderStatus.APPROVED_NO_RATES &&
  //   location.state.status !== 'undefined') ||
  // (location.state.status === PurchaseOrderStatus.CLOSED_PENDING_ORDER &&
  //   location.state.status !== 'undefined') ||
  // (location.state.status === PurchaseOrderStatus.CANCELLED_PENDING_ORDER &&
  //   location.state.status !== 'undefined')
  //   ? intialRateReviewHeaders
  //   : poDetailsTabs;

  const [key, setKey] = useState('details');
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [contactInfo, setContactInfo] = useState([]);
  const [seletedContactInfo, setSeletedContactInfo] = useState([]);
  const [seletedContactInfoKey, setSeletedContactInfoKey] = useState(0);
  const [refreshDoc, setRefreshDoc] = useState(false);
  const [affidavitData, setaffidavitData] = useState([]);

  const [agreeDisagree, setAgreeDisagree] = useState({
    isAgre: false,
    comments: '',
  });
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [item, setItem] = useState({ kindOfSelection: '', another: 'another' });
  const isLoading = useSelector(state => state.purchaseOrderDetails.isLoading);
  const { promiseInProgress: POLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.getPODetail,
    delay: 0,
  });
  // const { kindOfSelection } = item;

  let poContactInfo = [];

  const poLinesHeaders = [
    '',
    'Line',
    'Item',
    'Description',
    'Quantity',
    'Unit Cost',
    'Organization',
    'Line Cost',
    'Line Status',
  ];

  const handleAddClick = (newcontactList, contactList) => {
    let newcontactListCopy = _.cloneDeep(newcontactList);
    poContactInfo.push(newcontactListCopy);
    setContactInfo(poContactInfo);
    dispatch(
      addPOContact({
        data: [contactList],
        poId: props.poId,
      }),
    );
    dispatch(fetchPurchaseOrderDetails(props.poId));
    setAddModalShow(false);
  };

  const handleEditClick = editKey => {
    let newcontactListCopy = _.cloneDeep(purchaseOrder.contacts);
    setSeletedContactInfo(newcontactListCopy[editKey]);
    setSeletedContactInfoKey(editKey);
    setEditModalShow(true);
  };

  const handleContinueClick = (updatedPOList, select) => {
    let newcontactListCopy = _.cloneDeep(updatedPOList);
    let contactsCopy = _.cloneDeep(purchaseOrder.contacts);
    let poContactID = contactsCopy[seletedContactInfoKey].pocontactsid;
    select.name = newcontactListCopy.name;
    select.email = newcontactListCopy.email;
    select.phonenum = newcontactListCopy.phonenum;
    dispatch(
      updatePOContact({
        data: select,
        poId: props.poId,
        poContactID: poContactID,
      }),
    );
    dispatch(fetchPurchaseOrderDetails(props.poId));
    setEditModalShow(false);
  };

  const handleDeleteClick = editKey => {
    setSeletedContactInfoKey(editKey);
    setDeleteModalShow(true);
  };

  const handleDeleteContinueClick = editKey => {
    let contactsCopy = _.cloneDeep(purchaseOrder.contacts);
    let poContactID = contactsCopy[seletedContactInfoKey].pocontactsid;
    dispatch(
      deletePOContact({
        poId: props.poId,
        poContactID: poContactID,
      }),
    );
    dispatch(fetchPurchaseOrderDetails(props.poId));
    setDeleteModalShow(false);
  };

  const handleExpandRow = (event, polineid) => {
    if (expandedRows.includes(polineid)) {
      setExpandedRows([]);
    } else {
      setExpandedRows([polineid]);
    }
  };

  const isNull = field => {
    if (field === '' || field === null || field === undefined) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setContactInfo(poContactInfo);
    // const actions = user?.configObject[Page.PURCHASE_ORDER];
    const optionsAllowed = filterAllowedOptionsACL(
      user,
      ModuleACL.PURCHASEORDER,
      optionsTab,
    );
    setTabs(optionsAllowed);
    // setCanAgree(
    //   purchaseOrder.status === PurchaseOrderStatus.PENDING_INITIAL_REVIEW ||
    //     purchaseOrder.status ===
    //       PurchaseOrderStatus.PENDING_INITIAL_REVIEW_DECLINED,
    // );
    // setCanDisagree(
    //   purchaseOrder.status === PurchaseOrderStatus.PENDING_INITIAL_REVIEW,
    // );
    dispatch(setIsPDFLoading(false));
  }, []);

  const updatePOStatus = async isAgre => {
    setLoader(true);
    try {
      dispatch(fetchPurchaseOrderDetails(props.poId));
      const payLoadData = _.cloneDeep(purchaseOrder);
      let originalPayLoad = payLoadData.originalData;

      setSaveInProgress(true);
      let worklog = {};
      let message = '';
      if (isAgre) {
        message = 'Successfully accepted the Purchase Order';
        originalPayLoad.caastatus =
          PurchaseOrderStatus.PENDING_INITIAL_REVIEW_SUBMITTED;
        worklog = {
          ponum: purchaseOrder.ponum,
          logtype: 'TYUI',
          createdBy: user.userId,
          createdDate: new Date(),
          clientviewable: 'YES',
          description: 'Accept',
          siteid: purchaseOrder.siteID,
          orgid: purchaseOrder.organization,
          descriptionLongdescription: '',
          recordkey: purchaseOrder.ponum,
          class: 'PO',
          revisionnum: purchaseOrder.revisionNumber,
          poid: purchaseOrder.poid,
          enteredBy: user.userId,
          enteredDate: new Date(),
        };
      } else {
        message = 'Purchase Order is declined';
        originalPayLoad.caastatus =
          PurchaseOrderStatus.PENDING_INITIAL_REVIEW_DECLINED;
        worklog = {
          ponum: purchaseOrder.ponum,
          logtype: 'TYUI',
          createdBy: user.userId,
          createdDate: new Date(),
          clientviewable: 'YES',
          description: 'Reject',
          siteid: purchaseOrder.siteID,
          orgid: purchaseOrder.organization,
          descriptionLongdescription: agreeDisagree.comments,
          recordkey: purchaseOrder.ponum,
          class: 'PO',
          revisionnum: purchaseOrder.revisionNumber,
          poid: purchaseOrder.poid,
          enteredBy: user.userId,
          enteredDate: new Date(),
        };
      }

      if (_.isEmpty(originalPayLoad.poworklogs)) {
        originalPayLoad.poworklogs = new Array();
      }
      originalPayLoad.poworklogs.push(worklog);

      dispatch(
        addInvoiceSignature({
          poId: props.poId,
          esign: signatureBase64,
          callback: async data => {
            if (data) {
              setRefreshDoc(true);
            }
          },
        }),
      );

      // Call: Integration/db
      dispatch(
        initialReviewResponsePurchaseOrder({
          poId: originalPayLoad.poid,
          data: originalPayLoad,
          callback: async data => {
            if (data?.data?.error) {
              setLoader(false);
              onError(data?.data);
            } else {
              setLoader(false);
              setSaveInProgress(false);
              setCanAgree(false);
              setCanDisagree(false);
              displaySuccess(message);
              navigate(`/${ROUTES_STRING.purchaseOrders}`);
            }
          },
        }),
      );
    } catch (err) {
      setLoader(false);
      onError(err);
    }
  };

  const handleAcceptClick = () => {
    setSignatureModalForWorkAuth(true);
  };

  const handleWorkAuthContinueClick = () => {
    setSignatureModalForWorkAuth(false);
    updatePOStatus(true);
  };

  const nestedActivityHeader = [
    'Activity Number',
    'Description',
    'EST Total Hours',
    'EST Total $',
    'Complete %',
    'Actual Total Hours',
    'Actual Total $',
  ];

  /*
  const handleAgreeModalClick = () => {
    setSignatureModal(false);
    if (signatureBase64 !== '') {
      agreeDisagree.isAgre = true;
      agreeDisagree.comments = '';
      updatePOStatus(true);
    }
  };
  */
  const handleRequestVendorCompletion = () => {
    setShowRequestModal(false);
    dispatch(setApprovedButtonStatus(location.state.status));
    try {
      dispatch(
        requestVendorCompletionForm({
          poId: location.state.poId,
        }),
      );
      displayCompletionFormStatus('Requested Vendor Completion Form');
    } catch (err) {
      onError(err);
    }
  };

  const handleDisagreeClick = () => {
    setCommentsModal(true);
  };

  const handleDisagreeModalClick = agreeDisagreeFromModal => {
    agreeDisagree.isAgre = false;
    agreeDisagree.comments = agreeDisagreeFromModal.comment;
    setCommentsModal(false);
    updatePOStatus(false);
  };

  const viewFilePO = async fileInfo => {
    console.log(fileInfo);
    await viewFile(
      'purchase-orders/' +
        fileInfo.poid +
        '/attachments/' +
        fileInfo.poAttachmentNmbr,
      fileInfo.fileName,
    );
  };

  const viewFile = async (url, filename, file) => {
    await fileViewer({ url, filename, file });
  };

  const onError = data => {
    displayError('Something went wrong!');
  };

  const openSignitureModal = data => {
    setCompletionFormModal(false);
    setSignatureModal(true);
    setaffidavitData(data);
  };

  const handleFormContinueClick = () => {
    dispatch(
      completionFormSubmitRequest({
        poId: location.state.poId,
        esign: signatureBase64,
        state: affidavitData[0].state,
        county: affidavitData[0].county,
        city: affidavitData[0].city,
        nm_affiant: affidavitData[0].affiantName,
        office_held_affiant: affidavitData[0].affiantOffice,
        legal_nm_contractor: affidavitData[0].legalNameOfContractor,
        legal_nm_owner: affidavitData[0].legalNameOfOwner,
        contract_date: affidavitData[0].contractDate,
        contract_no: affidavitData[0].contractNo,
        proj_nm: affidavitData[0].projectName,
      }),
    );
  };

  return (
    <>
      <Tabs
        id="purchaseOrderDetilsPage"
        activeKey={key}
        onSelect={k => setKey(k)}
      >
        {tabs?.map((tab, index) => (
          <Tab
            eventKey={tab.key}
            title={tab.value}
            key={index}
            tabClassName="tab-item"
          >
            {tab.key == 'details' && (
              <>
                <div className="poDetailsContainer">
                  <Fragment>
                    <Form>
                      <div className="headerContainer">Dates</div>
                      <Row>
                        <Col sm={3}>
                          <Form.Group controlId="form.startDate">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                              value={purchaseOrder.startDate}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="form.endDate">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                              value={purchaseOrder.endDate}
                              disabled
                            />
                          </Form.Group>
                        </Col>

                        <Col sm={3}>
                          <Form.Group controlId="form.totalCast">
                            <Form.Label>Revision</Form.Label>
                            <Form.Control
                              value={purchaseOrder.revisionNumber}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="headerContainer">Contract</div>
                      <Row>
                        <Col sm={3}>
                          <Form.Group controlId="form.contract">
                            <Form.Label>Contract Number</Form.Label>
                            <Form.Control
                              value={purchaseOrder.contractRefnum}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="form.contractor">
                            <Form.Label>Contractor</Form.Label>
                            <Form.Control
                              value={purchaseOrder.contractorName}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="form.site">
                            <Form.Label>Site</Form.Label>
                            <Form.Control value={purchaseOrder.site} disabled />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="headerContainer">Description</div>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="form.scopeofWork">
                            <Form.Label>Scope of Work</Form.Label>
                            <Form.Control
                              value={purchaseOrder.scopeOfWork}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="form.pricingMethod">
                            <Form.Label>Pricing Method</Form.Label>
                            <Form.Control
                              value={purchaseOrder.pricingMethod}
                              disabled
                            />
                          </Form.Group>
                        </Col>

                        <Col sm={3}>
                          <Form.Group controlId="form.totalCast">
                            <Form.Label>Total Cost</Form.Label>
                            <Form.Control
                              value={purchaseOrder?.totalvalue?.toLocaleString()}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <Form.Group
                            controlId="form.reqtsappr"
                            style={{ display: 'flex' }}
                          >
                            <Form.Check
                              inline
                              name="group1"
                              disabled
                              checked={purchaseOrder?.reqtsappr ? true : false}
                              style={{ width: '25px' }}
                            />
                            <Form.Label
                              style={{ margin: '0px', marginTop: '22px' }}
                            >
                              Timesheet Approval Required?
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Fragment>
                </div>
                {IsAllowedACL(
                  user,
                  ModuleACL.PURCHASEORDER,
                  'can_request_completion_form',
                ) &&
                  [
                    PurchaseOrderStatus.APPROVED,
                    PurchaseOrderStatus.APPROVED_NO_RATES,
                  ].includes(purchaseOrder?.status) &&
                  user.userType !== UserType.VENDOR && (
                    <div className="closePurchaseOrder">
                      <p className="closePOHeader">Close Purchase Order:</p>
                      <Button
                        variant="primary"
                        style={{ width: 'auto' }}
                        onClick={() => setShowRequestModal(true)}
                      >
                        Request Vendor Completion Form
                      </Button>
                    </div>
                  )}
                {IsAllowedACL(
                  user,
                  ModuleACL.PURCHASEORDER,
                  'can_submit_completion_form',
                ) &&
                  purchaseOrder.status ===
                    PurchaseOrderStatus.REQUEST_VENDOR_COMPLETION_FORM &&
                  user.userType === UserType.VENDOR && (
                    <div className="closePurchaseOrder">
                      <p className="closePOHeader">Close Purchase Order:</p>
                      <Button
                        variant="primary"
                        onClick={() => setCompletionFormModal(true)}
                        style={{ width: 'auto' }}
                      >
                        Submit Vendor Completion Form
                      </Button>
                    </div>
                  )}
              </>
            )}
            {tab.key == 'contactInfo' && (
              <div className="poDetailsContainer">
                <div className="headerContainer">Vendor</div>
                <Form>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3" controlId="form.companyName">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                          type="text"
                          defaultValue={purchaseOrder.companyName}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={9} className="addNewbtn">
                      {IsAllowedACL(
                        user,
                        ModuleACL.PURCHASEORDER,
                        'can_edit_contact_info_purchaseorder',
                      ) && (
                        <span
                          onClick={() => setAddModalShow(true)}
                          className="float-right"
                        >
                          <AddIcon />
                        </span>
                      )}
                    </Col>
                  </Row>
                </Form>
                <div className="headerContainer">Contact</div>
                {purchaseOrder?.contacts?.map((col, editkey) => {
                  return (
                    <Fragment>
                      <div className="POContacts">
                        <Form>
                          <Row>
                            <Col sm={10}>
                              <Row>
                                <Col sm={3}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="pocontactName"
                                  >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={col.name}
                                      disabled
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={3}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="pocontactEmail"
                                  >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={col.email}
                                      disabled
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={3}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="pocontactPhoneNum"
                                  >
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={col.phonenum}
                                      disabled
                                    />
                                  </Form.Group>
                                </Col>
                                {col.role !== null &&
                                  col.role.map(role => {
                                    return (
                                      <Col sm={3}>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="rolesSelection "
                                        >
                                          <Form.Label>Role</Form.Label>
                                          <Form.Select
                                            aria-label="filterValues"
                                            value="--Select--"
                                            style={{
                                              backgroundColor: '#eceff3',
                                            }}
                                            disabled
                                            name="role"
                                          >
                                            {roles.map(
                                              (rol, index) =>
                                                rol.value === role && (
                                                  <option
                                                    key={index}
                                                    value={rol.value}
                                                  >
                                                    {rol.label}
                                                  </option>
                                                ),
                                            )}
                                          </Form.Select>
                                        </Form.Group>
                                      </Col>
                                    );
                                  })}
                              </Row>
                            </Col>
                            <Col sm={2}>
                              <Row>
                                <Col
                                  key={index}
                                  sm={12}
                                  className="addNewbtnContract"
                                >
                                  {IsAllowedACL(
                                    user,
                                    ModuleACL.PURCHASEORDER,
                                    'can_edit_contact_info_purchaseorder',
                                  ) && (
                                    <>
                                      <span
                                        onClick={e => handleEditClick(editkey)}
                                        className="float-right"
                                      >
                                        <EditIcon />
                                      </span>
                                      <span
                                        onClick={e =>
                                          handleDeleteClick(editkey)
                                        }
                                        className="float-right"
                                      >
                                        <DeleteIcon />
                                      </span>
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            )}
            {tab.key == 'address' && (
              <div className="poDetailsContainer linesContainer">
                <Fragment>
                  <Form>
                    <Row>
                      <Col sm={3} className="mb-2">
                        <Form.Group controlId="form.description">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            defaultValue={purchaseOrder.companyName}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3} className="mb-2">
                        <Form.Group controlId="form.streetAddress">
                          <Form.Label>Street Address</Form.Label>
                          <Form.Control
                            defaultValue={purchaseOrder.companyAddress1}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3} className="mb-2">
                        <Form.Group controlId="form.city">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            defaultValue={purchaseOrder.companyAddress2}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3} className="mb-2">
                        <Form.Group controlId="form.stateOrProvince">
                          <Form.Label>State or Province</Form.Label>
                          <Form.Control
                            defaultValue={purchaseOrder.companyAddress3}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3} className="mb-2">
                        <Form.Group controlId="form.postalCode">
                          <Form.Label>Postal Code</Form.Label>
                          <Form.Control
                            defaultValue={purchaseOrder.companyAddress4}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3} className="mb-2">
                        <Form.Group controlId="form.country">
                          <Form.Label>Country</Form.Label>
                          <Form.Control
                            defaultValue={purchaseOrder.companyAddress5}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Fragment>
              </div>
            )}
            {tab.key == 'poLines' && (
              <Fragment>
                <div className="poDetailsContainer linesContainer">
                  {purchaseOrder?.poLines?.length > 0 ? (
                    purchaseOrder?.poLines?.map((row, indx) =>
                      row.pricingmethod === 'ALW' &&
                      user.userType === UserType.VENDOR ? null : (
                        <div
                          style={{
                            border: 'thin solid #e4dfdf',
                          }}
                          className="mb-2"
                        >
                          <Row>
                            <Col sm={1}>
                              <div className="expandCollapseIcons">
                                <span
                                  className="expand"
                                  onClick={e =>
                                    handleExpandRow(e, row.polineid)
                                  }
                                >
                                  {expandedRows.includes(row.polineid) ? (
                                    <ExpandIcon />
                                  ) : (
                                    <CollapseIcon />
                                  )}
                                </span>
                              </div>
                            </Col>
                            <Col sm={11}>
                              <Table
                                hover
                                responsive
                                striped
                                borderless
                                style={{ border: 'none' }}
                              >
                                <thead>
                                  <tr style={{ textAlign: 'center' }}>
                                    {poLinesHeaders.map((header, idx) => (
                                      <th key={idx}>{header}</th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    key={indx}
                                    className="tRow table-hover-row"
                                    style={{ textAlign: 'center' }}
                                  >
                                    <td></td>
                                    <td>{row.polinenum}</td>
                                    <td>{row.itemnum}</td>
                                    <td>{row.description}</td>
                                    <td>{row.orderqty}</td>
                                    <td>{row.unitcost}</td>
                                    <td>{row.orgid}</td>
                                    <td>{row.linecost}</td>
                                    <td>{row.status}</td>
                                  </tr>
                                  <>
                                    {expandedRows.includes(row.polineid) ? (
                                      <>
                                        <tr
                                          style={{
                                            background: 'white',
                                            border: 'none',
                                          }}
                                        >
                                          <td colSpan={9}>
                                            <div>
                                              <Table
                                                hover
                                                responsive
                                                striped
                                                bordered={false}
                                              >
                                                <thead
                                                  style={{
                                                    textAlign: 'center',
                                                  }}
                                                >
                                                  <tr>
                                                    <th
                                                      style={{
                                                        width: '48px',
                                                        fontSize: '12px',
                                                        background: 'white',
                                                      }}
                                                    ></th>
                                                    <th
                                                      style={{
                                                        fontSize: '16px',
                                                      }}
                                                    >
                                                      Order Unit
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontSize: '16px',
                                                      }}
                                                    >
                                                      GL Debit Account
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontSize: '16px',
                                                      }}
                                                    >
                                                      Site
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontSize: '16px',
                                                      }}
                                                    >
                                                      Pricing Method
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontSize: '16px',
                                                      }}
                                                    >
                                                      Remaining Amount
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <>
                                                    <tr
                                                      key={row.id}
                                                      className="table-hover-row"
                                                      style={{
                                                        textAlign: 'center',
                                                        background: 'white',
                                                      }}
                                                    >
                                                      <td></td>
                                                      <td>{row.orderunit}</td>
                                                      <td>{row.gldebitacct}</td>
                                                      <td>{row.siteid}</td>
                                                      <td>
                                                        {row.pricingmethod}
                                                      </td>
                                                      <td>
                                                        {
                                                          row.remaininglineamount
                                                        }
                                                      </td>
                                                      <td></td>
                                                      <td></td>
                                                      <td></td>
                                                    </tr>
                                                  </>
                                                </tbody>
                                              </Table>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={9}>
                                            <Table
                                              hover
                                              responsive
                                              striped
                                              bordered={false}
                                              className="expandTable"
                                            >
                                              <thead
                                                style={{
                                                  textAlign: 'center',
                                                }}
                                              >
                                                <tr>
                                                  <span
                                                    style={{
                                                      fontSize: '19px',
                                                      fontWeight: 'bold',
                                                      marginBottom: '18px',
                                                      display: 'flex',
                                                    }}
                                                  >
                                                    Activities related to PO
                                                    line
                                                  </span>
                                                </tr>
                                                <tr
                                                  style={{
                                                    background: '#dcdcdc',
                                                  }}
                                                  className="table-hover-row"
                                                >
                                                  {nestedActivityHeader.map(
                                                    (header, idx) => (
                                                      <th key={idx}>
                                                        {header}
                                                      </th>
                                                    ),
                                                  )}
                                                </tr>
                                                {purchaseOrder.costActivities
                                                  .length > 0
                                                  ? purchaseOrder.costActivities.map(
                                                      (cost, indx) =>
                                                        cost.polinenum ===
                                                          row.polinenum && (
                                                          <tr
                                                            style={{
                                                              background:
                                                                '#f3f3f3',
                                                              fontWeight:
                                                                'normal',
                                                            }}
                                                          >
                                                            <th>
                                                              {cost.activitynum}
                                                            </th>
                                                            <th>
                                                              {cost.description}
                                                            </th>
                                                            <th>
                                                              {cost.esttothours}
                                                            </th>
                                                            <th>
                                                              {
                                                                cost.esttotaldollar
                                                              }
                                                            </th>
                                                            <th>
                                                              {cost.percomplete}
                                                            </th>
                                                            <th>
                                                              {cost.acttothours}
                                                            </th>
                                                            <th>
                                                              {
                                                                cost.acttotaldollar
                                                              }
                                                            </th>
                                                          </tr>
                                                        ),
                                                    )
                                                  : null}
                                              </thead>
                                              <tbody></tbody>
                                            </Table>
                                          </td>
                                        </tr>
                                      </>
                                    ) : null}
                                  </>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </div>
                      ),
                    )
                  ) : (
                    <div>PO Lines Record unvailable</div>
                  )}
                </div>
              </Fragment>
            )}
            {tab.key == 'workAuth' && (
              <Fragment>
                {loader ? (
                  <ClipLoader
                    color="#009cde"
                    cssOverride={{
                      top: '50vh',
                      left: '50vw',
                      position: 'absolute',
                      zIndex: 1,
                    }}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <>
                    <div className="additionalAttachmentsContainer linesContainer">
                      {purchaseOrder.isPDFLoading ? (
                        <div>
                          <span>No Work Authorization Document </span>
                        </div>
                      ) : (
                        <WorkAuthPDF
                          poId={props.poId}
                          refresh={refreshDoc}
                          isSetStyle={true}
                        />
                      )}
                    </div>
                    {IsAllowedACL(
                      user,
                      ModuleACL.PURCHASEORDER,
                      'can_submit_workauth_purchaseorder',
                    ) &&
                      !purchaseOrder.isPDFLoading && (
                        <Fragment>
                          <ButtonArea
                            primaryOnClick={() => handleAcceptClick()}
                            secondaryOnClick={() => handleDisagreeClick()}
                            primaryLabel={
                              purchaseOrder.status ===
                                PurchaseOrderStatus.PENDING_INITIAL_REVIEW ||
                              purchaseOrder.status ===
                                PurchaseOrderStatus.PENDING_INITIAL_REVIEW_DECLINED
                                ? 'Accept'
                                : ''
                            }
                            secondaryLabel={
                              purchaseOrder.status ===
                              PurchaseOrderStatus.PENDING_INITIAL_REVIEW
                                ? 'Disagree'
                                : ''
                            }
                            primaryDisabled={!canAgree}
                            secondaryDisabled={!canDisagree}
                          />
                        </Fragment>
                      )}
                  </>
                )}
              </Fragment>
            )}
            {tab.key == 'additionalAttachments' && (
              <Fragment>
                {purchaseOrder?.poAttachments?.length > 0 ? (
                  purchaseOrder?.poAttachments?.map((attachment, indx) => (
                    <Fragment>
                      <div>
                        <PDFIcon />
                        <Button
                          active={false}
                          variant="link"
                          className="pdfBtn"
                          onClick={() => viewFilePO(attachment)}
                        >
                          {attachment.fileName}
                        </Button>
                      </div>
                      {IsAllowedACL(
                        user,
                        ModuleACL.PURCHASEORDER,
                        'can_submit_work_auth_purchaseorder',
                      ) && (
                        <Fragment>
                          <ButtonArea
                            primaryOnClick={() => handleAcceptClick()}
                            secondaryOnClick={() => handleDisagreeClick()}
                            primaryLabel="Accept"
                            secondaryLabel="Disagree"
                            primaryDisabled={!canAgree}
                            secondaryDisabled={!canDisagree}
                          />
                        </Fragment>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <div className="additionalAttachmentsContainer linesContainer">
                    No Attachments Available !
                  </div>
                )}
              </Fragment>
            )}
          </Tab>
        ))}
      </Tabs>
      <ConfirmationModal
        showModal={showRequestModal}
        setShowModal={setShowRequestModal}
        handleContinueClick={() => handleRequestVendorCompletion()}
        title="Request Vendor Completion Form"
        body="Are you sure you want to request Vendor to submit the Completion Form?"
      />
      <Modal show={completionFormModal} dialogClassName="completionPopupDialog">
        <Modal.Body>
          <AffidavitForm closeParentModal={openSignitureModal} />
        </Modal.Body>
      </Modal>
      <AddNewContactModal
        show={addModalShow}
        setShowModal={setAddModalShow}
        handleAddClick={handleAddClick}
      />
      <EditContactModal
        show={editModalShow}
        setShowModal={setEditModalShow}
        selectedList={seletedContactInfo}
        handleContinueClick={handleContinueClick}
      />
      <DeleteContactModal
        show={deleteModalShow}
        setShowModal={setDeleteModalShow}
        handleDeleteContinueClick={handleDeleteContinueClick}
      />
      <AddSignatureModal
        show={signatureModal}
        setShowModal={setSignatureModal}
        signatureBase64={signatureBase64}
        setSignatureBase64={setSignatureBase64}
        handleContinueClick={() => handleFormContinueClick()}
        title="Submit Purchase Order Completion Form"
        subTitle="By signing, contractor/vendor is acknowledging this will initiate the Purchase Order (PO) closing process and no further invoices will be applied to this PO."
      />
      <AddSignatureModal
        show={signatureModalForWorkAuth}
        setShowModal={setSignatureModalForWorkAuth}
        signatureBase64={signatureBase64}
        setSignatureBase64={setSignatureBase64}
        handleContinueClick={() => handleWorkAuthContinueClick()}
        title="Submit Work Authorization"
        subTitle="By signing, contractor/vendor is acknowledging this will initiate the Purchase Order (PO)."
      />
      <CommentsModal
        show={commentsModal}
        setShowModal={setCommentsModal}
        agreeDisagree={agreeDisagree}
        handleContinueClick={handleDisagreeModalClick}
      />
      <ClipLoader
        color="#009cde"
        loading={POLoading}
        cssOverride={{
          top: '50vh',
          left: '50vw',
          position: 'absolute',
          zIndex: 1,
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};
export { PurchaseOrderDetails };
