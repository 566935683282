import { BuPath } from 'constants/buPath';
import { TimesheetStatus } from 'enums/statuses.ts';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { IsAllowedACL } from 'utils/aclHelper';
import { ModuleACL } from 'enums/entitlements.ts';
import 'react-datepicker/dist/react-datepicker.css';

const TimesheetApproverField = props => {
  const user = useSelector(state => state.auth);
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);
  const timesheet = useSelector(state => state.timesheetDetail);

  const {
    control,
    getApproverMenuOptions,
    handleTimesheetApproval,
    timesheetApproverOptions,
    Option,
    SingleValue,
    newTimesheet,
  } = props;

  return (
    <>
      {BuPath.GEN.includes(purchaseOrder?.buPath) &&
        !newTimesheet &&
        IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_approve_timesheet') && (
          <div className="aepContainer costApprover">
            <Form.Label className="approverLabel">
              Cost Activities Approver
            </Form.Label>
            <br />
            <Row>
              {timesheet?.timesheetApprovals.map((tSheetApprover, index) => (
                <Col sm={3} key={index}>
                  <Form.Group controlId="timesheetApprover">
                    <Form.Label>Approver {index + 1}</Form.Label>
                    <Controller
                      control={control}
                      name={`approver${index}`}
                      render={({ onChange, value, name, ref }) => (
                        <>
                          {BuPath.GEN.includes(purchaseOrder.buPath) ? (
                            <ReactSelect
                              id="approverList"
                              classNamePrefix="tSheetApprovers"
                              value={getApproverMenuOptions().find(
                                obj => obj.value === tSheetApprover?.approver,
                              )}
                              isClearable
                              isSearchable={true}
                              isDisabled={[
                                TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
                                TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
                                TimesheetStatus.TIMESHEET_APPROVED,
                                TimesheetStatus.TIMESHEET_INVOICED,
                              ].includes(timesheet?.caastatus)}
                              placeholder="Select"
                              options={getApproverMenuOptions()}
                              menuPosition="absolute"
                              maxMenuHeight={600}
                              onChange={e =>
                                handleTimesheetApproval(e?.value, index)
                              }
                              components={{
                                Option,
                                SingleValue,
                              }}
                              closeMenuOnSelect={true}
                              hideSelectedOptions={true}
                            />
                          ) : (
                            <Form.Select
                              value={tSheetApprover?.approver}
                              onChange={e =>
                                handleTimesheetApproval(e.target?.value, index)
                              }
                              disabled={[
                                TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
                                TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
                              ].includes(timesheet?.caastatus)}
                            >
                              {timesheetApproverOptions.map(
                                (approver, eIndex) => (
                                  <option key={eIndex} value={approver.value}>
                                    {approver.label}
                                  </option>
                                ),
                              )}
                            </Form.Select>
                          )}
                        </>
                      )}
                    />
                  </Form.Group>
                </Col>
              ))}
            </Row>
          </div>
        )}
    </>
  );
};

export { TimesheetApproverField };
